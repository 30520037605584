<template>
<div class="scope">
    <VueSlickCarousel v-bind="slide_settings" v-model="slide" v-if="banners.length!=0 && $vssWidth>=1024">
        <template v-for="(banner,index) in banners">
            <a :key="index" class="slide-child" :href="banner.promotion_link" target="_blank">
                <img class="img-fluid" :src="banner.promotion_image">
            </a>
        </template>
    </VueSlickCarousel>
    <VueSlickCarousel v-bind="slide_settings_mobile" v-model="slide" v-if="banners.length!=0 && $vssWidth<1024">
        <template v-for="(banner,index) in banners">
            <a :key="index" class="slide-child-mobile" :href="banner.promotion_link" target="_blank">
                <img class="img-fluid" :src="banner.promotion_image">
            </a>
        </template>
    </VueSlickCarousel>
</div>
</template>

<script>
import {
    mapGetters,
    mapActions
} from 'vuex'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    components: {
        VueSlickCarousel
    },
    computed: {
        ...mapGetters({
            banners: 'GET_BANNER'
        })
    },
    data() {
        return {
            slide: 0,
            sliding: null,
            slide_settings: {
                arrows: true,
                dots: true,
                centerMode: true
            },
            slide_settings_mobile: {
                arrows: false,
                dots: false,
                focusOnSelect: true,
                centerMode: false
            },
            api_load: true
        }
    },
    async mounted() {
        this.SET_BANNER()
        this.api_load = false
    },
    methods: {
        ...mapActions(['SET_BANNER']),
        onSlideStart() {
            this.sliding = true
        },
        onSlideEnd() {
            this.sliding = false
        },
        onBeforeCarousel() {
            //console.log('our carousel is ready')
        },
    }
}
</script>

<style scoped>
.scope {
    margin: 0% 20% 0% 20%;
}

@media screen and (max-width: 1600px) {
    .scope {
        margin: 0% 5% 0% 5%;
    }
}

@media screen and (max-width: 500px) {
    .scope {
        margin: 0% 5% 0% 5%;
    }
}

.slide-child {
    padding: 5px;
    -webkit-filter: opacity(0.5);
    -moz-filter: opacity(0.5);
    -o-filter: opacity(0.5);
    -ms-filter: opacity(0.5);
    filter: opacity(0.5);
}

.slick-active .slide-child {
    -webkit-filter: opacity(1);
    -moz-filter: opacity(1);
    -o-filter: opacity(1);
    -ms-filter: opacity(1);
    filter: opacity(1) !important;
}
</style>
